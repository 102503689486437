/** nft card css start */

.nft-card-container {
  background: var(--color-bg-primary);
  box-shadow: inset -5px -5px 10px var(--color-bg-neumorphismsecondary),
    inset 5px 5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
  padding: 10px;
}

.nft-inner-img-container {
  background: var(--color-bg-primary);
  box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
    inset -5px -5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
  padding: 10px;
  height: 250px;
}

.nft-inner-img-container img,
.nft-inner-img-container video {
  height: 230px;
  /* width changed to 310px to cover the widened NftCard for larger screens */
  /* width: auto; */
  width: 310px;
  margin: auto;
  border-radius: 10px;
  object-fit: cover;
}

.nft-loan-inner-img-container {
  background: var(--color-bg-primary);
  box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
    inset -5px -5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
  padding: 10px;
  height: 120px;
}

.nft-loan-inner-img-container img {
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 10px;
  object-fit: cover;
}

.nft-inner-img-edit-container {
  border: 1px solid var(--color-text-gold);
}

.nft-inner-img-pay-container {
  border: 1px solid var(--color-text-blue);
}

.nft-counter-timer-gold {
  color: var(--color-text-gold);
}

.nft-counter-timer-blue {
  color: var(--color-text-blue);
}

.nft-green {
  color: var(--color-text-green);
}

.nft-red {
  color: var(--color-text-red);
}
.nft-lend-active {
  border: 1px solid var(--color-text-green);
  border-radius: 20px;
  color: var(--color-text-green);
}

.nft-market-state-active {
  color: var(--color-text-green);
  border-bottom: 2px solid var(--color-text-green);
}

/** nft card css end */
