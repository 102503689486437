/** nft market place css start */

.current-page {
  font-weight: bolder;
  font-size: x-large;
}

.pagination-button {
  background: var(--color-bg-primary);
  box-shadow: inset 4px 4px 8px var(--color-bg-neumorphismprimary),
    inset -4px -4px 8px var(--color-bg-neumorphismsecondary);
}

.pagination-container {
  background: var(--color-bg-primary);
  box-shadow: inset 4px 4px 8px var(--color-bg-neumorphismprimary),
    inset -4px -4px 8px var(--color-bg-neumorphismsecondary);
}

.active-page {
  color: var(--color-text-green);
}

/** nft market place css end */
