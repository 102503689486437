/** nft media player css start */
.video-container {
  position: relative;
}

.video-controls {
  position: absolute;
  bottom: 1%;
  right: 3.5%;
  width: 1.75rem;
  height: auto;
}

#play-pause-btn {
  width: 2.5rem;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50% 50%;
  z-index: 1;
}
/** nft media player css end */
