/** NFT Detail style css start */

@media (min-width: 320px) {
  .nft-detail-img-container {
    background: var(--color-bg-primary);
    box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
      inset -5px -5px 10px var(--color-bg-neumorphismprimary);
    border-radius: 15px;
    padding: 10px;
    height: 270px;
    width: 250px;
  }

  .nft-detail-img-container img,
  .nft-detail-img-container video {
    height: 250px;
    width: auto;
    margin: auto;
    border-radius: 10px;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .nft-detail-img-container {
    background: var(--color-bg-primary);
    box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
      inset -5px -5px 10px var(--color-bg-neumorphismprimary);
    border-radius: 15px;
    padding: 10px;
    height: 420px;
    width: 300px;
  }

  .nft-detail-img-container img,
  .nft-detail-img-container video {
    height: 400px;
    width: auto;
    margin: auto;
    border-radius: 10px;
    object-fit: cover;
  }
}

@media (min-width: 1024px) {
  .nft-detail-img-container {
    background: var(--color-bg-primary);
    box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
      inset -5px -5px 10px var(--color-bg-neumorphismprimary);
    border-radius: 15px;
    padding: 10px;
    height: 460px;
    width: 340px;
  }

  .nft-detail-img-container img,
  .nft-detail-img-container video {
    height: 440px;
    width: auto;
    margin: auto;
    border-radius: 10px;
    object-fit: cover;
  }
}

.nft-detail-green-color-text {
  color: var(--color-text-green);
}

.nft-detail-blue-color-text {
  color: var(--color-text-blue);
}

.nft-detail-input {
  background: var(--color-bg-primary);
  box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
    inset -5px -5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
}

.nft-radio-button-container input[type="radio"] {
  -webkit-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  background: var(--color-bg-primary);
  box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
    inset -5px -5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
  border: 1px solid #bdcada;
  flex: none;
  position: relative;
}

.nft-radio-button-container input[type="radio"]:checked {
  width: 20px;
  height: 20px;
  background: #00dd9a;
  box-shadow: inset 3px 3px 6px #92ffde, inset -3px -3px 6px #09a173;
  border: 1px solid #bdcada;
  border-radius: 15px;
}

.nft-radio-button-container input[type="radio"]:after {
  content: " ";
  position: absolute;
  height: 10px;
  width: 10px;
  background: var(--color-bg-primary);
  border-radius: 10px;
  top: 4px;
  left: 4px;
  opacity: 0;
}

.nft-radio-button-container input[type="radio"]:checked:after {
  opacity: 1;
}

.nft-detail-bid-img-container {
  background: var(--color-bg-primary);
  box-shadow: inset 5px 5px 10px var(--color-bg-neumorphismsecondary),
    inset -5px -5px 10px var(--color-bg-neumorphismprimary);
  border-radius: 15px;
  padding: 10px;
  height: 120px;
  width: 120px;
}

.nft-detail-bid-img-container img {
  height: 100px;
  width: auto;
  margin: auto;
  border-radius: 10px;
}

.attribute-section {
  max-height: 120px;
  overflow-y: auto;
}

.token-select {
  background-color: var(--color-bg-primary);
}

/** NFT Detail style css end */
